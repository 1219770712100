import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { ImLocation } from "react-icons/im";
import locateicon from '../../assetsRoot/home/icon/Group 1597882864.png'
import phoneicon from '../../assetsRoot/home/icon/Group 1597882866.png'
import mailicon from '../../assetsRoot/home/icon/Group 1597882865.png'
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import line from '../../assetsRoot/heading/Line 415.png'
import contactimg from '../../assetsRoot/heading/CONTACT US.png'
import toast from 'react-hot-toast';

const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    message: yup.string().required('Message is required'),
});

function ContactUs() {

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const formRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                reset(); // Reset the form when clicking outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [reset]);


    const onSubmit = async (data) => {
        console.log(data);
        reset()
        toast.success('Submitted Successfully')
    };

    return (
        <>
            <div id="contact" className=' py-12 '>
                <div className=' xl:px-[8%] px-[5%] flex items-center justify-between  lg:pb-5 gap-12 mb-8'>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                    <div className='flex justify-center items-end relative'>
                        <img className='relative' src={contactimg} alt="our fleet" />
                        <h1 className='absolute -bottom-4 text-3xl font-bold text-primary uppercase '>contact us</h1>
                    </div>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                </div>
                <div className='xl:px-[8%] px-[5%]'>
                    <h2 className='font-semibold text-xl'>Get in touch with us</h2>
                    <p className='text-para lg:text-sm xl:text-base'>We are here for you! How can we help?</p>
                </div >
                <div className='xl:px-[8%] px-[5%] grid grid-cols-12 gap-5 md:gap-5 lg:gap-20'>
                    <div className='col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12'>
                        <div className='flex flex-col lg:flex-row md:flex-row justify-between gap-y-5 mt-5 lg:mt-8 '>
                            <div >
                                <img className='mr-auto ml-auto justify-center mb-4' src={locateicon} alt="" />
                                <p className=' text-para text-center lg:text-sm xl:text-base'>26/1, (999) 38 Cross,<br />23rd Main 4th 'T' Block,<br></br> Jayanagar,Bangalore <br />- 560041</p>
                            </div>
                            <div>
                                <img className='mr-auto ml-auto justify-center mb-4' src={phoneicon} alt="" />

                                <p className='text-para text-center lg:text-sm xl:text-base'>+91-98808 44220</p>
                                <p className='text-para text-center lg:text-sm xl:text-base'> +91-98809 99994 </p>
                            </div>
                            <div>
                                <img className='mr-auto ml-auto justify-center mb-4' src={mailicon} alt="" />
                                <p className='text-para text-center lg:text-sm xl:text-base'>info@annapoorna.in</p>
                            </div>
                        </div>
                        <div ref={formRef} className=' mt-10 w-[100%]'>
                            <form onSubmit={handleSubmit(onSubmit)} className='px-10 pt-10 border-2 rounded-xl border-primary'>
                                <div className='mb-5'>
                                    <label className='text-para lg:text-sm xl:text-base text-center' name='name'>Full Name</label>
                                    <input
                                        type="text"
                                        {...register('name')}
                                        className={`input-style border-2 w-full mt-3  p-2 rounded mb-1 lg:text-sm xl:text-base
                                    ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                                        placeholder="Name"
                                    />
                                    {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
                                </div>
                                <div className='mb-5'>
                                    <label className='text-para lg:text-sm xl:text-base' name='name'>Email</label>
                                    <input
                                        type="text"
                                        {...register('email')}
                                        className={`input-style border-2 w-full mt-3  p-2 rounded mb-1 lg:text-sm xl:text-base
                                    ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                                        placeholder="Email"
                                    />
                                    {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}

                                </div>
                                <div className='mb-5'>
                                    <label className='text-para lg:text-sm xl:text-base' name='name'>Message</label>
                                    <textarea
                                        {...register('message')}
                                        className={`input-style border-2 w-full mt-3  p-2 rounded mb-1 lg:text-sm xl:text-base
                                    ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
                                        rows="4"
                                        placeholder="Message"
                                    />
                                    {errors.message && <p className="text-red-500 text-sm ">{errors.message.message}</p>}

                                </div>
                                <div className='flex justify-center -bottom-20'>
                                    <button
                                        type="submit"
                                        className="button-style -mb-5 bg-gradient-to-r from-green to-primary px-10 bg-primary text-white p-3 rounded-full"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-span-12 xl:col-span-6 lg:col-span-6  md:col-span-12 mt-5 lg:mt-8'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.7829017997824!2d77.58823027064982!3d12.921670388124856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1509d9c1b949%3A0x79d5265560a183c3!2s26%2C%20999%2C%2038th%20Cross%20Rd%2C%204th%20T%20Block%20East%2C%20Jayanagara%209th%20Block%2C%20Jayanagar%2C%20Bengaluru%2C%20Karnataka%20560041!5e0!3m2!1sen!2sin!4v1715817515522!5m2!1sen!2sin"
                            title="google map"
                            width="100%"
                            height="100%"
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                            className='rounded-xl border-2 border-primary'
                        />
                    </div>

                </div>
            </div >
            <div className='bg-primary text-white flex justify-center p-5 mt-5 font-semibold capitalize'>
                <h3 className='text-xl md:text-2xl lg:text-2xl xl:text-3xl'>For Support   <span className='ms-5'> +91 98808 44220</span></h3>
            </div>

        </>
    )
}

export default ContactUs
