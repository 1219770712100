import React, { useState } from 'react'
import "react-multi-carousel/lib/styles.css";
import line from '../../assetsRoot/heading/Line 415.png'
import galleryimg from '../../assetsRoot/heading/GALLERY.png'
import Marquee from "react-fast-marquee";
import Gallery1 from '../../assetsRoot/Gallery/gallery (1).jpeg'
import Gallery2 from '../../assetsRoot/Gallery/gallery (2).jpeg'
import Gallery3 from '../../assetsRoot/Gallery/gallery (3).jpeg'
import Gallery4 from '../../assetsRoot/Gallery/gallery (4).jpeg'
import Gallery5 from '../../assetsRoot/Gallery/gallery (5).jpeg'
import Gallery6 from '../../assetsRoot/Gallery/gallery (6).jpeg'
import Gallery7 from '../../assetsRoot/Gallery/gallery (7).jpeg'
import Gallery8 from '../../assetsRoot/Gallery/gallery (8).jpeg'
import Gallery9 from '../../assetsRoot/Gallery/gallery (9).jpeg'
import Gallery10 from '../../assetsRoot/Gallery/gallery (10).jpeg'
import Gallery11 from '../../assetsRoot/Gallery/gallery (11).jpeg'
import Gallery12 from '../../assetsRoot/Gallery/gallery (12).jpeg'
import Gallery13 from '../../assetsRoot/Gallery/gallery (14).jpeg'
import Carousel from 'react-multi-carousel';
import ImagePreviewer from '../../componentsRoot/ImagePreviewer';

function Gallery() {
    const [openModal, setOpenModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(Gallery1);

    const toggle = () => {
        setOpenModal(!openModal)
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <div id="photo" className=' py-12  text-4xl bg-background1'>
                <div className='lg:px-[8%] md:px-[8%] px-[5%] flex items-center justify-between  lg:pb-5 gap-8 mb-8'>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                    <div className='flex justify-center items-end relative'>
                        <img className='relative' src={galleryimg} alt="our fleet" />
                        <h1 className='absolute -bottom-4 text-3xl font-bold text-primary uppercase '>gallery</h1>
                    </div>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                </div>
                {/* <Marquee
                    className="w-100 testing"
                    direction="left"
                    speed={50} // Adjust the speed to match scrollamount
                    loop={0} // Infinite looping
                    gradient={false} // Disable gradient
                >
                    <div className='flex justify-between items-center gap-10 ms-10'>
                        <div >
                            <img draggable='false' src={Gallery1} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg ' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery2} alt="Gallery" className='rounded-xl h-64  border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery3} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery4} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery5} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery6} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery7} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery8} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery9} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery10} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery11} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery12} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                        <div >
                            <img draggable='false' src={Gallery13} alt="Gallery" className='rounded-xl h-64 border-4 border-white shadow-lg' />
                        </div>
                    </div>
                </Marquee> */}
                <Carousel
                    responsive={responsive}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    arrows={false}
                    autoPlaySpeed={2000}
                    infinite={true}
                    dotListClass="custom-dot-list-product"
                    itemClass="carousel-item-padding-40-px"
                    className='w-full ps-6'
                >
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery1); toggle() }} src={Gallery1} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery2); toggle() }} src={Gallery2} alt="Gallery" className='rounded-xl h-64 object-cover  border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery3); toggle() }} src={Gallery3} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery4); toggle() }} src={Gallery4} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery5); toggle() }} src={Gallery5} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery6); toggle() }} src={Gallery6} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery7); toggle() }} src={Gallery7} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery8); toggle() }} src={Gallery8} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery9); toggle() }} src={Gallery9} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery10); toggle() }} src={Gallery10} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery11); toggle() }} src={Gallery11} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery12); toggle() }} src={Gallery12} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                    <div >
                        <img draggable='false' onClick={() => { setSelectedImage(Gallery13); toggle() }} src={Gallery13} alt="Gallery" className='rounded-xl h-64 object-cover border-4 border-white shadow-lg cursor-pointer' />
                    </div>
                </Carousel>
                <ImagePreviewer openModal={openModal} toggle={toggle} image={selectedImage} />
            </div>
        </>
    )
}

export default Gallery
