import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import axios from 'axios';
import { subjectFilterComponent } from '../Components.js';
import OTPInput from 'react-otp-input';
import { Button, Tooltip } from 'flowbite-react';
import { FaArrowLeft } from 'react-icons/fa';
import { IoIosCheckmarkCircle } from 'react-icons/io';

const BookingTwo = ({ type }) => {
    const [otp, setOtp] = useState();
    const [isOtp, setIsOtp] = useState(false);

    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [vechiclemodel, setVechicleModel] = useState([]);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        companyName: '',
        vehicle: '',
        requirements: ''

    });
    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Required';
        if (!formData.mobile) newErrors.mobile = 'Required';
        if (!formData.email) newErrors.email = 'Required';
        if (!formData.companyName) newErrors.companyName = 'Required';
        if (!formData.vehicle) newErrors.vehicle = 'Required';

        if (!formData.requirements) newErrors.requirements = 'Required';
        return newErrors;
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobile') {
            // Prevent typing more than 10 digits
            if (value.length <= 10) {
                setFormData((prev) => ({ ...prev, [name]: value }));
            }
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSelectChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            setFormData((prev) => ({ ...prev, vehicle: selectedOption.value }));
        } else {
            // Handle the case when selectedOption is null or doesn't have a value property
            // For example, you could set a default value or handle it in some other way
            console.error("Selected option is null or doesn't have a value property");
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {

            console.log('Form submitted:', formData);
        } else {
            setErrors(validationErrors);
        }
    };
    const getSubmit = async () => {
        console.log(formData);
        const payload = {
            customerDetails: {
                name: formData.name,
                contactNumber: formData.mobile,
                email: formData.email
            },
            journeyType: type,
            description: formData.requirements,
            companyName: formData.companyName,

            vehicle: formData.vehicle
        }
        try {
            const response = await axios.post('https://devapi.annapoorna.in/journeyDetails', payload);
            console.log(response);
            setIsOtp(true)
        } catch (error) {
            console.log(error);
        }
    };
    const getvechiclemodel = async () => {
        try {
            const response = await axios.get('https://devapi.annapoorna.in/auth/vehicle/');
            setVechicleModel(response.data?.result?.rows);

        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getvechiclemodel();

    }, []);
    const subjectArray = vechiclemodel?.map((item) => (
        {
            value: item?._id,
            avatar: item?.image,
            label: item?.name,
            seatCount: item?.seatCount,
            Ac: item?.Ac,
            Availability: item?.availability

        }
    ))
    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '12rem', // Equivalent to max-h-48 in Tailwind
            overflowY: 'auto', // Enable vertical scrolling
            zIndex: 50, // Ensure the dropdown has a high z-index
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '12rem', // Equivalent to max-h-48 in Tailwind
            overflowY: 'auto', // Enable vertical scrolling
        }),
    };
    const handleOtpVerify = async () => {
        const payload = {
            mobile: formData.mobile,
            otp: otp
        }
        try {
            const response = await axios.post('https://devapi.annapoorna.in/otp/verify', payload);
            console.log(response);
            setIsOtpVerified(true)
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <>
            {isOtp ? (<div className='flex justify-center items-center'>

                {isOtpVerified ? (
                    <div className="flex items-center h-[350px] justify-center">
                        <div className='text-center'>
                            <h1 className='text-2xl  font-bold '><IoIosCheckmarkCircle className='w-20 h-20 text-primary mx-auto' /></h1>
                            <h1 className='md:text-[28px] xl:text-xl lg:text-[20px] text-gray-300 font-semibold'>Thank you for Booking !</h1>
                            <p className="mt-2 md:text-[18px] xl:text-[16px] lg:text-[14px] text-gray-300">We appreciate your booking. You will receive a confirmation message shortly.</p>

                        </div>
                    </div>
                ) : (
                    <div className="text-center p-6">
                        <div className='flex items-center justify-center'>
                            <Tooltip>
                                <h1 className='text-[20px] mb-3 me-1 cursor-pointer' onClick={() => setIsOtp(false)}><FaArrowLeft /></h1>
                            </Tooltip>
                            <h1 className='text-2xl mb-3 font-bold'> OTP Verification</h1>
                        </div>
                        <h3 className="mb-5 text-md font-normal text-gray-500 placeholder:text-gray-50">
                            Enter the OTP you received at <br /><span className='text-navydark font-bold'>+91 {formData?.mobile}</span>
                        </h3>
                        <div className='flex justify-center'>
                            <OTPInput
                                value={otp}
                                onChange={(e) => setOtp(e)}
                                inputType="tel"
                                numInputs={6}
                                inputStyle="custom-otp-field"
                                renderInput={(props) => <input {...props} />}
                            />

                        </div>
                        <div className="flex justify-center mt-5">
                            <Button color="dark" size='sm' disabled={!otp || otp.length !== 6}
                                onClick={handleOtpVerify}
                                className='bg-primary  px-3 py-2'
                            >
                                Verify
                            </Button>
                        </div>
                        <p className='text-[16px] mt-3 text-gray-500'>Didn't receive code? <span className=' text-semibold text-primary underline cursor-pointer'>Request again</span></p>
                    </div>
                )}
            </div>
            ) : (
                <div>
                    <div className=' xl:px-4 xl:py-3 px-3 py-1 md:px-4 md:py-3 lg:px-2 lg:py-1  rounded-[10px] mx-0'>
                        <form onSubmit={handleSubmit}>
                            <div className='flex flex-col-2 gap-2 mb-2'>
                                <div className=' w-full'>
                                    <input
                                        type="text"
                                        name='name'
                                        value={formData.name}
                                        onChange={handleChange}
                                        className={`  hover:bg-gray-700 text-semibold bg-transparent  text-[14px] md:text-[16px] xl:text-[16px] lg:text-[13px] px-3 py-1 md:px-3 md:py-2 border-2 rounded-[5px] border-gray-300 w-full xl:px-3 xl:py-2 lg:px-2 lg:py-2 dark:border-gray-50 placeholder:text-gray-50  ${errors.name ? 'border-red-500' : ''}`}
                                        placeholder="Name"
                                    />
                                    {/* {errors.name && <p className="px-3  text-red-500 text-[13px] md:text-[16px] xl:text-[16px] lg:text-[13px]">{errors.name}</p>} */}
                                </div>
                                <div className=' w-full'>
                                    <input
                                        type="number"
                                        name='mobile'
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        className={`  hover:bg-gray-700 text-semibold text-[14px] md:text-[16px] xl:text-[16px] lg:text-[13px] lg:px-2 lg:py-2 px-3 py-1 md:px-3 md:py-2 border-2 rounded-[5px] border-gray-300 w-full xl:px-3 xl:py-2 bg-transparent  dark:border-gray-50 placeholder:text-gray-50  ${errors.mobile ? 'border-red-500' : ''}`}
                                        placeholder="Mobile Number"
                                    />
                                    {/* {errors.mobile && <p className="px-3  text-red-500 text-[13px] md:text-[16px] xl:text-[16px] lg:text-[13px]">{errors.mobile}</p>} */}
                                </div>
                            </div>

                            <>
                                <div className='flex flex-col-2 gap-2 mb-2'>
                                    <div className=' w-full'>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className={`  hover:bg-gray-700 text-semibold text-[14px] md:text-[16px] xl:text-[16px] lg:text-[13px] px-3 py-1 md:px-3 md:py-2 border-2 rounded-[5px] border-gray-300 w-full xl:px-3 xl:py-2 lg:px-2 lg:py-2 bg-transparent  dark:border-gray-50 placeholder:text-gray-50  ${errors.email ? 'border-red-500' : ''}`}
                                            placeholder="Email"
                                        />
                                        {/* {errors.email && <p className="px-3 text-red-500 text-[13px] md:text-[16px] xl:text-[16px] lg:text-[13px] -mt-0 xl:-mt-1 lg:-mt-1">{errors.email}</p>} */}
                                    </div>
                                    <div className='w-full'>
                                        <input
                                            type="text"
                                            name="companyName"
                                            value={formData.companyName}
                                            onChange={handleChange}
                                            className={`   hover:bg-gray-700 text-semibold text-[14px] md:text-[16px] xl:text-[16px] lg:text-[13px] px-3 py-1 md:px-3 md:py-2 border-2 rounded-[5px] border-gray-300 w-full xl:px-3 xl:py-2 lg:px-2 lg:py-2 bg-transparent  dark:border-gray-50 placeholder:text-gray-50   ${errors.companyName ? 'border-red-500' : ''}`}
                                            placeholder="Company Name"
                                        />
                                        {/* {errors.companyName && <p className="px-3  text-red-500 text-[13px] md:text-[16px] xl:text-[16px] lg:text-[13px] -mt-1">{errors.companyName}</p>} */}
                                    </div>
                                </div>
                            </>

                            <div className='mb-2'>


                                <Select
                                    isClearable
                                    options={subjectArray}
                                    onChange={handleSelectChange}
                                    classNamePrefix="select"
                                    className={`dark:border-gray-600 border-gray-300  hover:bg-gray-700 
                 text-[14px] md:text-[16px] xl:text-[16px] 
                 lg:text-[13px] ${errors.vehicle ? 'border-red-500 border' : ''}`}
                                    components={{ Option: subjectFilterComponent }}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            border: state.isFocused ? '2px solid #00923F' : '2px solid #D1D5DB',
                                            borderRadius: '4px',
                                            backgroundColor: "transparent",
                                            boxShadow: 'none',

                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            color: 'white'
                                        })
                                    }}
                                    placeholder="Select Vehicle"
                                />



                            </div>



                            {/* {errors.vehicle && <p className="  border rounded-[10px] text-red-500 text-[13px] md:text-[16px] xl:text-[16px] lg:text-[13px]  xl-mt-4  md:-mt-1 lg:-mt-4 -mt-0 ml-2">{errors.vehicle}</p>} */}


                            <textarea name="requirements"
                                value={formData.requirements}
                                onChange={handleChange}
                                className={`xl:px-3 xl:py-2 mb-2    hover:bg-gray-700  
                                w-full  xl:pb-2 md:pb-5 text-[14px] md:text-[18px] xl:text-[16px] 
                                lg:text-[13px]  px-2 py-1 md:px-3 md:py-1  border-2 rounded-[5px] border-gray-300    lg:px-2  bg-transparent 
                                 dark:border-gray-50  placeholder:text-gray-50  lg:pb-4  
                                 ${errors.requirements ? 'border-red-500' : ''}`} row="4" placeholder="Requirements"></textarea>

                            {/* {errors.requirements && <p className=" text-red-500 text-[13px] md:text-[16px] xl:text-[16px] lg:text-[13px] -mt-2 md:-mt-3 xl:-mt-5 lg:-mt-3 xl:mb-4  ml-2 ">{errors.requirements}</p>} */}
                            <div className='flex justify-center pt-3 xl:pt-6'>
                                <button type='submit' onClick={getSubmit}
                                    className="lg:-mb-10 xl:-mb-12  cursor-pointer   hover:text-bold rounded-[50px]
                                     px-4 py-2  text-[18px] text-white md:text-[20px] md:rounded-[50px] md:px-3 md:py-3  
                                      md:text-white bg-gradient-to-r from-green to-primary lg:rounded-[50px] 
                                     xl:px-10 xl:py-1 lg:px-8 lg:py-2 lg:text-white lg:text-[16px] xl:text-[18px]   ">
                                    Book Now
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>

    )
}

export default BookingTwo