import React from 'react'
import Marquee from "react-fast-marquee";
import client1 from '../../assetsRoot/Client/client1.jpg'
import client3 from '../../assetsRoot/Client/client3.jpg'
import client4 from '../../assetsRoot/Client/client4.jpg'
import client5 from '../../assetsRoot/Client/client5.jpg'
import client6 from '../../assetsRoot/Client/client6.jpg'
import client7 from '../../assetsRoot/Client/client7.jpg'
import client8 from '../../assetsRoot/Client/client8.jpg'
import client9 from '../../assetsRoot/Client/client9.jpg'
import client10 from '../../assetsRoot/Client/new/ANKO.png'
import client11 from '../../assetsRoot/Client/new/BRAND.png'
import client12 from '../../assetsRoot/Client/new/HCL.png'
import client13 from '../../assetsRoot/Client/new/JUBILANT.png'
import client14 from '../../assetsRoot/Client/new/L&T.png'
import client15 from '../../assetsRoot/Client/new/MICROSOFT.png'
import client16 from '../../assetsRoot/Client/new/NATWEST.png'
import client17 from '../../assetsRoot/Client/new/PAYPAL.png'
import client18 from '../../assetsRoot/Client/new/PWC.png'
import client19 from '../../assetsRoot/Client/new/SKF.png'
import client20 from '../../assetsRoot/Client/new/TVS.png'
import Carousel from 'react-multi-carousel';


function OurClients() {
    const images = [client1, client3, client4, client5, client6, client7, client8, client9, client10]

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 10
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 10
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 6
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3
        }
    };


    return (
        <>
            <div className=' bg-gradient-to-r from-green to-primary py-8'>
                {/* <Marquee
                    className="w-100 testing"
                    direction="left"
                    speed={50} // Adjust the speed to match scrollamount
                    loop={0} // Infinite looping
                    gradient={false} // Disable gradient
                >
                    <div className='flex justify-between items-center gap-10 ms-10'>
                        <div >
                            <img src={client1} alt="client 1" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client3} alt="client 3" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client4} alt="client 4" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client5} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client6} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client7} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client8} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client9} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client5} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client6} alt="client 5" className='rounded-md h-20' />
                        </div>
                        <div>
                            <img src={client8} alt="client 5" className='rounded-md h-20' />
                        </div>
                    </div>
                </Marquee> */}
                <Carousel
                    responsive={responsive}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    arrows={false}
                    autoPlaySpeed={2000}
                    infinite={true}
                    dotListClass="custom-dot-list-product"
                    itemClass="carousel-item-padding-40-px"
                    className='w-full ps-6 cursor-pointer'
                >
                    <div >
                        <img draggable='false' src={client1} alt="client 1" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client3} alt="client 3" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client4} alt="client 4" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client5} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client6} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client7} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client8} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client9} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client10} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client11} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client12} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client13} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client14} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client15} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client16} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client17} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client18} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client19} alt="client 5" className='rounded-md h-20' />
                    </div>
                    <div>
                        <img draggable='false' src={client20} alt="client 5" className='rounded-md h-20' />
                    </div>
                </Carousel>
            </div>
        </>
    )
}

export default OurClients
