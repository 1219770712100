import { components } from 'react-select';
import { FaCheckCircle } from "react-icons/fa";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { IoMdCar } from "react-icons/io";
import { FaTimesCircle } from "react-icons/fa";
export const subjectFilterComponent = ({ data, ...props }) => {
    if (!data) {
        return null;
    }
    const isSelected = props.isSelected;
    return (
        <components.Option {...props}>
            <div style={{ scrollbarWidth: 'none' }} className={`flex gap-4 border-2 border-gray-400  px-2 py-2 md:px-4 md:py-2 lg:px-3 lg:py-3 xl:px-5 xl:py-3 rounded-[10px] w-full ${isSelected ? 'bg-white text-black ' : ''} `}>

                <div>
                    <img className=" d-block rounded-circle me-50 size-16 md:size-20 lg:size-12 xl:size-16  bg-white border rounded-[10px]  " src={data.avatar} height="16" width="16" alt={data.label} />
                </div>

                <div className="">
                    {/* <img src={data?.coverImg} className="rounded-circle" style={{ height: '45px', width: '45px' }} alt="icon" /> */}
                    <div className=" gap-2 md:gap-2  lg:gap-2 xl:gap-2">
                        <div className=''>
                            <span className='flex items-center '>
                                <IoMdCar className="text-primary" />
                                <h1  className="mt-2 md:mt-0 lg:mt-0 xl:mt-0 ml-1 mb-1 xl:mb-1 lg:mb-0 xl:ml-2 lg:ml-1 font-bold text-[13px]  md:text-[16px] lg:text-[11px] xl:text-[15px]">
                                    {data.label}
                                </h1>
                            </span>
                        </div>
                        <div>
                            <h3  className=" mt-2 md:mt-0.5 lg:mt-0.5 xl:mt-0.5">

                                {data.Availability === true ? (
                                    <span className='flex items-center '>
                                    <FaCheckCircle className="text-primary" />
                                    <p  className="mt-2 md:mt-0 lg:mt-0 xl:mt-0 ml-1 mb-1 xl:mb-1 lg:mb-0 xl:ml-2 lg:ml-1  text-[13px]  md:text-[16px] lg:text-[11px] xl:text-[15px]">
                                  Available
                                </p> </span>
                                ) : (
                                    <span className='flex items-center'><FaTimesCircle className="text-primary" />
                                        <span className=" xl:ml-2 lg:ml-1 lg:text-[11px] xl:text-[15px] text-[13px] md:text-[16px]">Not Available</span>
                                    </span>
                                )}
                            </h3>
                        </div>
                    </div>
                    <div className='flex gap-10 md:gap-10 lg:gap-10 xl:gap-12 mt-2'>
                        <h3  className="mb-0">
                            <span className='flex items-center'> <MdAirlineSeatReclineExtra className="text-primary" />
                                <span className="ml-2 lg:text-[11px] xl:text-[15px] text-[13px] md:text-[16px]">{data.seatCount} </span>
                            </span>
                        </h3>
                        <h3  className="mb-0 ">
                            {data.Ac === true ? (

                                <span className='flex items-center'> <TbAirConditioning className="text-primary" />
                                    <span className="ml-2 lg:text-[11px] xl:text-[15px] text-[13px] md:text-[16px]">Ac</span>
                                </span>
                            ) : (
                                <span className='flex items-center'> <TbAirConditioning className="text-primary" />
                                    <span className="ml-2 lg:text-[11px] xl:text-[15px] text-[13px] md:text-[16px]">Non Ac</span>
                                </span>
                            )}
                        </h3>

                    </div>



                </div>
            </div>


        </components.Option>
    );
};