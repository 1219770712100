import React, { useEffect } from 'react'
import { IoIosArrowDroprightCircle } from "react-icons/io";
import round from '../../assetsRoot/home/Ellipse 8788.png'
import Aos from 'aos';
import valueaddition1 from '../../assetsRoot/value-additions/valueaddition1.png'
import valueaddition2 from '../../assetsRoot/value-additions/valueaddition2.png'
import valueaddition3 from '../../assetsRoot/value-additions/valueaddition3.png'
import valueaddition4 from '../../assetsRoot/value-additions/valueaddition4.png'

function ValueAddition() {

    const data = [
        {
            title: "Modern Fleet of Vehicles",
            description: "Our modern fleet of vehicles are equipped with the latest amenities and safety features to provide a comfortable and secure commuting experience for your employees.",
        },
        {
            title: "Customization Options",
            description: "Customization options for transport services, such as selecting preferred pickup and drop-off locations or adjusting routes based on employee feedback and demand.",
        },
        {
            title: "Quality Assurance",
            description: "Our Fleet of Transport undergo regular maintenance and inspections of our vehicles to ensure reliability, cleanliness, and comfort for our passengers every time they ride with us.",
        },
        {
            title: "Safety Measures",
            description: "Vehicles adhere to safety standards and regulations, with features such as seat belts, first aid kits, and emergency exits to ensure passenger safety during transit.",
        },
    ]


    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <>
            <div className='xl:px-[8%] px-[5%] py-12  bg-backgroundGreen relative'>
                <div data-aos="zoom-in">
                    <img src={round} alt="background car" className="absolute bottom-0 left-0" />
                </div>
                <div data-aos="zoom-in">
                    <img src={round} alt="background car" className="absolute place-content-end  left-56" />
                </div>
                <div data-aos="zoom-in">
                    <img src={round} alt="background car" className="absolute bottom-0 right-20 top-0" />
                </div>
                <div data-aos="zoom-in">
                    <img src={round} alt="background car" className="absolute bottom-0 right-32 top-36" />
                </div>
                <div data-aos="zoom-in">
                    <img src={round} alt="background car" className="absolute bottom-0 right-96 top-20" />
                </div>
                <h2 className='uppercase text-3xl font-bold text-center pb-7 text-white'>value additions</h2>
                <div className='grid grid-cols-12 gap-5'>
                    <div className='col-span-12 lg:col-span-8 text-white text-md'>
                        {data.map((item, i) => (
                            <div key={i} className='md:mb-5 lg::mb-10 mt-5'>
                                <div className="lg:mb-3 md:mb-3 mb-2  lg:flex lg:items-center md:items-start flex">
                                    <span><IoIosArrowDroprightCircle size={30} className='me-2' /></span>
                                    <p className='uppercase font-semibold text-xl'>{item.title}</p>
                                </div>
                                <p className='text-sm'>{item.description}</p>
                            </div>
                        ))}
                    </div>
                    <div className='col-span-12 lg:col-span-4 lg:ps-10'>
                        <img className='object-cover w-full' src={valueaddition1} alt="valueaddition1" />
                        <div className='grid grid-cols-12 '>
                            <div className='col-span-12 lg:col-span-6'>
                                <img className='object-cover w-full' src={valueaddition3} alt="valueaddition3" />
                            </div>
                            <div className='col-span-12 lg:col-span-6'>
                                <img className='object-cover w-full' src={valueaddition4} alt="valueaddition4" />
                            </div>
                        </div>
                        <img className='object-cover w-full' src={valueaddition2} alt="valueaddition2" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ValueAddition
