import React from 'react'
import line from '../../assetsRoot/heading/Line 415.png'
import serviceimg from '../../assetsRoot/heading/OUR SERVICES.png'
import service from '../../assetsRoot/home/Ellipse 8783.png'
import arrow from '../../assetsRoot/home/Vector 24350.png'
import arrow2 from '../../assetsRoot/home/Vector 24351.png'
import one from '../../assetsRoot/services/1.png'
import two from '../../assetsRoot/services/2.png'
import three from '../../assetsRoot/services/3.png'
import four from '../../assetsRoot/services/4.png'
import five from '../../assetsRoot/services/5.png'

function Services() {
    return (
        <>
            <div id="serv" className='px-[5%] xl:px-[8%] py-12 '>

                <div className='flex items-center justify-between  lg:pb-5 gap-12 mb-8'>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="one" />
                    </div>
                    <div className='flex justify-center items-end relative'>
                        <img className='relative' src={serviceimg} alt="our fleet" />
                        <h1 className='absolute -bottom-4 text-3xl font-bold text-primary uppercase '>our services</h1>
                    </div>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="one" />
                    </div>
                </div>
                <div className='lg:grid lg:grid-cols-12 gap-24 hidden '>
                    <div className='grid lg:col-span-4 row-span-2'>
                        <div className='col-span-12 text-center mb-10'>
                            <div className='flex flex-col justify-center items-center'>
                                <div className='mb-3'>
                                    <img src={one} alt="one" />
                                </div>
                                <h6 className='lg:text-base xl:text-lg font-semibold flex text-center'>Cab Services</h6>
                            </div>
                            <p className='lg:text-[13px] xl:text-[15px] text-para pt-3 xl:pt-5'>ANP provide, experienced drivers,reliable service,cost-effective and flexible scheduling,
                                options.We may arrange for the luxury cars,of your dreams to arrive at your destination.</p>
                        </div>
                        <div className='col-span-12 text-center'>
                            <div className='flex flex-col justify-center items-center'>
                                <div className='mb-3'>
                                    <img src={two} alt="one" />
                                </div>
                                <h6 className='lg:text-base xl:text-lg font-semibold flex text-center'>Bus Service</h6>
                            </div>
                            <p className='lg:text-[13px] xl:text-[15px] text-para pt-3 xl:pt-5'>ANP provide, experienced drivers,reliable service,
                                cost-effective and flexible scheduling, options.We may arrange for the luxury cars,of your
                                dreams to arrive at your destination.</p>
                        </div>
                    </div>
                    <div className='lg:col-span-4 col-span-12 container2'>
                        <img src={service} alt="service" />
                    </div>
                    <div className='lg:col-span-4 grid row-span-2'>
                        <div className='text-center col-span-12 mb-10'>
                            <div className='flex flex-col justify-center items-center'>
                                <div className='mb-3'>
                                    <img src={five} alt="one" />
                                </div>
                                <h6 className='lg:text-base xl:text-lg font-semibold flex text-center'>Airport Taxi Service</h6>
                            </div>
                            <p className='lg:text-[13px] xl:text-[15px] text-para pt-3 xl:pt-5'>ANP provide, experienced drivers,reliable service,
                                cost-effective and flexible scheduling, options.We may arrange for the luxury cars,of your
                                dreams to arrive at your destination.</p>
                        </div>
                        <div className='text-center '>
                            <div className='flex flex-col justify-center items-center'>
                                <div className='mb-3'>
                                    <img src={four} alt="one" />
                                </div>
                                <h6 className='lg:text-base xl:text-lg font-semibold flex text-center'>Corporate Travels</h6>
                            </div>
                            <p className='lg:text-[13px] xl:text-[15px] text-para pt-3 xl:pt-5'>ANP provide, experienced drivers,reliable service,
                                cost-effective and flexible scheduling, options.We may arrange for the luxury cars,of your
                                dreams to arrive at your destination.</p>
                        </div>
                    </div>
                </div>
                <div className='lg:grid grid-cols-3  hidden'>
                    <div className='xl:mt-[10px]'>
                        <img src={arrow} alt="arrow" className='h-[100px] w-[400px] ps-32' />
                    </div>
                    <div className='text-center'>
                        <div className='flex flex-col justify-center items-center'>
                            <div className='mb-3'>
                                <img src={three} alt="one" />
                            </div>
                            <h6 className='lg:text-base xl:text-lg font-semibold flex text-center'>Outstation Travels</h6>
                        </div>
                        <p className='lg:text-[13px] xl:text-[15px] text-para pt-3 xl:pt-5'>ANP provide, experienced drivers,reliable service,
                            cost-effective and flexible scheduling, options.We may arrange for the luxury cars,of your
                            dreams to arrive at your destination.</p>
                    </div>
                    <div className='xl:mt-[10px]'>
                        <img src={arrow2} alt="one" className='h-[100px] w-[320px] pe-32' />
                    </div>
                </div>

                <div className='lg:hidden block'>
                    <div className='col-span-12 '>
                        <div className='flex items-center gap-2'>
                            <img className='w-8 h-8' src={one} alt="one" />
                            <h6 className='font-semibold'>Cab Services</h6>
                        </div>
                        <p className='text-[14px] text-para pt-1'>ANP provide, experienced drivers,reliable service,cost-effective and flexible scheduling,
                            options.We may arrange for the luxury cars,of your dreams to arrive at your destination.</p>
                    </div>
                    <div className='col-span-12 pt-5'>
                        <div className='flex items-center gap-2'>
                            <img className='w-8 h-8' src={two} alt="one" />
                            <h6 className='font-semibold'>Bus Service </h6>
                        </div>
                        <p className='text-[14px] text-para pt-1'>ANP provide, experienced drivers,reliable service,cost-effective and flexible scheduling,
                            options.We may arrange for the luxury cars,of your dreams to arrive at your destination.</p>
                    </div>
                    <div className='col-span-12 pt-5'>
                        <div className='flex items-center gap-2'>
                            <img className='w-8 h-8' src={three} alt="one" />
                            <h6 className='font-semibold'>Airport Taxi Service </h6>
                        </div>
                        <p className='text-[14px] text-para pt-1'>ANP provide, experienced drivers,reliable service,cost-effective and flexible scheduling,
                            options.We may arrange for the luxury cars,of your dreams to arrive at your destination.</p>
                    </div>
                    <div className='col-span-12 pt-5'>
                        <div className='flex items-center gap-2'>
                            <img className='w-8 h-8' src={four} alt="one" />
                            <h6 className='font-semibold'>Corporate Travels</h6>
                        </div>
                        <p className='text-[14px] text-para pt-1'>ANP provide, experienced drivers,reliable service,cost-effective and flexible scheduling,
                            options.We may arrange for the luxury cars,of your dreams to arrive at your destination.</p>
                    </div>
                    <div className='col-span-12 pt-5'>
                        <div className='flex items-center gap-2'>
                            <img className='w-8 h-8' src={five} alt="one" />
                            <h6 className='font-semibold'>Outstation Travels</h6>
                        </div>
                        <p className='text-[14px] text-para pt-1'>ANP provide, experienced drivers,reliable service,cost-effective and flexible scheduling,
                            options.We may arrange for the luxury cars,of your dreams to arrive at your destination.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services
