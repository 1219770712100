import React from 'react'
import NavBar from '../../componentsRoot/NavBar/NavBar'
import Banner from './Banner'
import Fleet from './Fleet'
import Details from './Details'
import AboutUs from './AboutUs'
import Services from './Services'
import ValueAddition from './ValueAddition'
import WhyChooseUs from './WhyChooseUs'
import FooterIndex from '../../componentsRoot/Footer'
import Team from './Team'
import Gallery from './Gallery'
import OurClients from './OurClients'

function Home() {
    return (
        <>
            <NavBar />
            <Banner />
            <OurClients />
            <Fleet />
            <Details />
            <AboutUs />
            <Services />
            <ValueAddition />
            {/* <WhyChooseUs /> */}
            <Team />
            <Gallery />
            <FooterIndex />

        </>
    )
}

export default Home
