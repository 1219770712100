import React, { useState } from 'react';
import BookThree from './step/BookThree';
import BookingOne from './step/BookingOne';
import BookingTwo from './step/BookingTwo';

const BookIndex = () => {
  const [radio, setRadio] = useState('corporateCab');

  return (
    <div className='xl:px-4 xl:py-3 px-4 py-3 md:px-4 md:py-3 lg:px-4 lg:py-2 backdrop-blur-lg rounded-[10px]'>
      {/* <div className="flex flex-col md:flex-row xl:flex-row lg:flex-row mt-2 gap-2 md:gap-4 lg:gap-2 xl:gap-2 ml-1 xl:ml-2 mb-5 lg:justify-between lg:text-center md:text-center">
        <div className="flex items-center space-x-2">
          <input
            type="radio"
            id="instantBooking"
            name="bookingType"
            value="instantBooking"
            checked={radio === 'instantBooking'}
            onChange={() => setRadio('instantBooking')}
            className="form-radio h-4 w-4 text-green-500 border-gray-300 focus:ring-green-500 checked:bg-green-500"
          />
          <label
            htmlFor="instantBooking"
            className={`cursor-pointer text-[16px] xl:text-[15px] lg:text-[12px] md:text-[20px] p-2 ${radio === 'instantBooking' ? 'text-white' : 'text-white'}`}
          >
            Instant Booking
          </label>
        </div>

        <div className="flex items-center space-x-2">
          <input
            type="radio"
            id="corporateCab"
            name="bookingType"
            value="corporateCab"
            checked={radio === 'corporateCab'}
            onChange={() => setRadio('corporateCab')}
            className="form-radio h-4 w-4 text-green-500 border-gray-300 focus:ring-green-500 checked:bg-green-500"
          />
          <label
            htmlFor="corporateCab"
            className={`cursor-pointer text-[16px] xl:text-[15px] lg:text-[12px] md:text-[20px] p-2 ${radio === 'corporateCab' ? 'text-white' : 'text-white'}`}
          >
            Corporate Booking
          </label>
        </div>

        <div className="flex items-center space-x-2">
          <input
            type="radio"
            id="outstation"
            name="bookingType"
            value="outstation"
            checked={radio === 'outstation'}
            onChange={() => setRadio('outstation')}
            className="form-radio h-4 w-4 text-green-500 border-gray-300 focus:ring-green-500 checked:bg-green-500"
          />
          <label
            htmlFor="outstation"
            className={`cursor-pointer text-[16px] xl:text-[15px] lg:text-[12px] md:text-[20px] p-2 ${radio === 'outstation' ? 'text-white' : 'text-white'}`}
          >
            Outstation
          </label>
        </div>
      </div> */}
      <div className='py-10'>
        {/* {radio === 'corporateCab' && <BookingTwo />}
        {radio === 'instantBooking' && <BookingOne />}
        {radio === 'outstation' && <BookThree />} */}
        <BookingTwo />
      </div>
    </div>
  );
};

export default BookIndex;
