import './App.css';
import Home from './containers/Home/Home';


function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
