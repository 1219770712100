import React from 'react'
import line from '../../assetsRoot/heading/Line 415.png'
import fleetimg from '../../assetsRoot/heading/OUR FLEET.png'
import slicker1 from '../../assetsRoot/home/slider/Rectangle 34625531.png'
import slicker2 from '../../assetsRoot/home/slider/Rectangle 34625531-2.png'
import slicker3 from '../../assetsRoot/home/slider/Rectangle 34625531 (1).png'
import slicker4 from '../../assetsRoot/home/slider/tata.png'
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

function Fleet() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <div id="fleet" className='xl:px-[8%] px-[5%] py-10 lg:py-15 md:py-15 xl:mt-25  bg-background1'>
                <div className=' flex items-center justify-between  lg:pb-5 md:gap-12 mb-8'>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                    <div className='flex justify-center items-end relative'>
                        <img className='relative' src={fleetimg} alt="our fleet" />
                        <h1 className='absolute -bottom-4 text-3xl font-bold text-primary uppercase '>our fleet</h1>
                    </div>
                    <div className='lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                </div>
                <div className='flex justify-center '>
                    <p className='text-center pb-8 lg:w-[70%] text-para text-sm lg:text-sm xl:text-base'>Experience luxury and reliability with our diverse fleet of meticulously maintained vehicles, tailored to provide comfort and peace of mind on every ride.</p>
                </div>
                <Carousel
                    responsive={responsive}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    arrows={false}
                    autoPlaySpeed={2000}
                    infinite={true}
                    dotListClass="custom-dot-list-product"
                    itemClass="carousel-item-padding-40-px"
                    className='w-full h-[380px]'
                >

                    {/* <div className='grid grid-cols-12 gap-4'> */}

                    <div className='col-span-12 lg:col-span-4 bg-white gap-3 justify-center shadow-md p-3 mx-3'>
                        <p className='bg-secondary px-3 py-1 absolute right-3 text-white'> 7+1 Seats</p>
                        <div className='justify-around'>
                            <img src={slicker1} alt="" className='justify-center w-full h-[250px] object-cover' />
                        </div>
                        <div className='flex justify-center bg-primary p-3 text-white shadow-sm'>
                            <span><h6>Cabs</h6></span>
                            {/* <span><h6>Starts at ₹30/km</h6></span> */}
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-4 bg-white gap-3 justify-center shadow-md p-3 mx-3'>
                        <p className='bg-secondary px-3 py-1 absolute right-3 text-white'>25 Seats</p>
                        <div className='justify-around'>
                            <img src={`https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241106163907801/IMG_4246.JPG`} alt="" className='justify-center w-full h-[250px] object-cover' />
                        </div>
                        <div className='flex justify-center bg-primary p-3 text-white shadow-sm'>
                            <span><h6>Bus</h6></span>
                            {/* <span><h6>Starts at ₹30/km</h6></span> */}
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-4 bg-white gap-3 justify-center shadow-md p-3 mx-3'>
                        <p className='bg-secondary px-3 py-1 absolute right-3 text-white'> 4+1 Seats</p>
                        <div className='justify-around'>
                            <img src={slicker4} alt="" className='justify-center w-full h-[250px] object-cover' />
                        </div>
                        <div className='flex justify-center bg-primary p-3 text-white shadow-sm'>
                            <span><h6>Cabs - Sedan</h6></span>
                            {/* <span><h6>Starts at ₹30/km</h6></span> */}
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-4 bg-white gap-3 justify-center shadow-md p-3 mx-3'>
                        <p className='bg-secondary px-3 py-1 absolute right-3 text-white'> 12+1 Seats</p>
                        <div className='justify-around'>
                            <img src={slicker2} alt="" className='justify-center w-full h-[250px] object-cover' />
                        </div>
                        <div className='flex justify-center bg-primary p-3 text-white shadow-sm'>
                            <span><h6>Tempo traveller</h6></span>
                            {/* <span><h6>Starts at ₹30/km</h6></span> */}
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-4 bg-white gap-3 justify-center shadow-md p-3 mx-3'>
                        <p className='bg-secondary px-3 py-1 absolute right-3 text-white'>32 Seats</p>
                        <div className='justify-around'>
                            <img src={`https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241106163907801/IMG_4246.JPG`} alt="" className='justify-center w-full h-[250px] object-cover' />
                        </div>
                        <div className='flex justify-center bg-primary p-3 text-white shadow-sm'>
                            <span><h6>Bus</h6></span>
                            {/* <span><h6>Starts at ₹30/km</h6></span> */}
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-4 bg-white gap-3 justify-center shadow-md p-3 mx-3'>
                        <p className='bg-secondary px-3 py-1 absolute right-3 text-white'> 7+1 Seats</p>
                        <div className='justify-around'>
                            <img src={slicker1} alt="" className='justify-center w-full h-[250px] object-cover' />
                        </div>
                        <div className='flex justify-center bg-primary p-3 text-white shadow-sm'>
                            <span><h6>Cabs</h6></span>
                            {/* <span><h6>Starts at ₹30/km</h6></span> */}
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-4 bg-white gap-3 justify-center shadow-md p-3 mx-3'>
                        <p className='bg-secondary px-3 py-1 absolute right-3 text-white'> 4+1 Seats</p>
                        <div className='justify-around'>
                            <img src={slicker4} alt="" className='justify-center w-full h-[250px] object-cover' />
                        </div>
                        <div className='flex justify-center bg-primary p-3 text-white shadow-sm'>
                            <span><h6>Cabs - Sedan</h6></span>
                            {/* <span><h6>Starts at ₹30/km</h6></span> */}
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-4 bg-white gap-3 justify-center shadow-md p-3 mx-3'>
                        <p className='bg-secondary px-3 py-1 absolute right-3 text-white'>40 Seats</p>
                        <div className='justify-around'>
                            <img src={`https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241106163907801/IMG_4246.JPG`} alt="" className='justify-center w-full h-[250px] object-cover' />
                        </div>
                        <div className='flex justify-center bg-primary p-3 text-white shadow-sm'>
                            <span><h6>Bus</h6></span>
                            {/* <span><h6>Starts at ₹30/km</h6></span> */}
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-4 bg-white gap-3 justify-center shadow-md p-3 mx-3'>
                        <p className='bg-secondary px-3 py-1 absolute right-3 text-white'> 12+1 Seats</p>
                        <div className='justify-around'>
                            <img src={slicker2} alt="" className='justify-center w-full h-[250px] object-cover' />
                        </div>
                        <div className='flex justify-center bg-primary p-3 text-white shadow-sm'>
                            <span><h6>Tempo traveller</h6></span>
                            {/* <span><h6>Starts at ₹30/km</h6></span> */}
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-4 bg-white gap-3 justify-center shadow-md p-3 mx-3'>
                        <p className='bg-secondary px-3 py-1 absolute right-3 text-white'>50 Seats</p>
                        <div className='justify-around'>
                            <img src={`https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241106163907801/IMG_4246.JPG`} alt="" className='justify-center w-full h-[250px] object-cover' />
                        </div>
                        <div className='flex justify-center bg-primary p-3 text-white shadow-sm'>
                            <span><h6>Bus</h6></span>
                            {/* <span><h6>Starts at ₹30/km</h6></span> */}
                        </div>
                    </div>
                    {/* </div> */}
                </Carousel>
            </div>
        </>
    )
}

export default Fleet
