import React, { useEffect, useState } from 'react'
import car from '../../assetsRoot/details/car_290131 1.png'
import group from '../../assetsRoot/details/group_921347 1.png'
import Driver from '../../assetsRoot/details/driver_3785281 1.png'
import users from '../../assetsRoot/details/users_391194 1.png'
import CountUp from "react-countup";


function Details() {



    return (
        <>
            <div className='lg:bg-car bg-black h-[auto] lg:h-[350px] w-[100%] bg-no-repeat lg:bg-cover flex justify-around items-center'>
                <div className='xl:px-[8% px-[5%] py-12  grid grid-cols-12 gap-y-10 lg:gap-y-0 lg:gap-20 md:gap-8 sm:gap-5'>
                    {/* <div className='px-[15%] py-12 pt-24 flex justify-between items-center '> */}
                    <div className='col-span-12 lg:col-span-3 md:col-span-3 sm:col-span-3'>
                        <div className='border-2 border-solid border-primary bg-white p-4 w-[80px] h-[80px] rounded-full flex mr-auto ml-auto'>
                            <img src={car} alt="car" />
                        </div>
                        <h4 className='text-2xl text-white text-center font-bold pt-4'> <CountUp duration={2} end={1500} />+</h4>
                        <p className='font-bold text-white text-center pt-3'>Fleet Available</p>
                    </div>
                    <div className='col-span-12 lg:col-span-3 md:col-span-3 sm:col-span-3'>
                        <div className='border-2 border-solid border-primary bg-white p-4 w-[80px] h-[80px] rounded-full flex mr-auto ml-auto'>
                            <img src={group} alt="car" /></div>
                        <h4 className='text-2xl font-bold text-center text-white pt-4'> <CountUp duration={1} end={20000} />+</h4>
                        <p className='font-bold text-white text-center pt-3'>Happy Users</p>
                    </div>
                    <div className='col-span-12 lg:col-span-3 md:col-span-3 sm:col-span-3'>
                        <div className='border-2 border-solid border-primary bg-white p-4 w-[80px] h-[80px] rounded-full flex mr-auto ml-auto'>
                            <img src={Driver} alt="car" /></div>
                        <h4 className='text-2xl text-white text-center font-bold pt-4'> <CountUp duration={2} end={999} />+</h4>
                        <p className='font-bold text-white text-center pt-3'>Our Drivers</p>
                    </div>
                    <div className='col-span-12 lg:col-span-3 md:col-span-3 sm:col-span-3'>
                        <div className='border-2 border-solid border-primary bg-white p-4 w-[80px] h-[80px] rounded-full flex mr-auto ml-auto'>
                            <img src={users} alt="car" /></div>
                        <h4 className='text-2xl text-white text-center font-bold pt-4'> <CountUp duration={3} end={150} />+</h4>
                        <p className='font-bold text-white text-center pt-3'>Our Team</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Details
