import { Button, Modal } from 'flowbite-react'
import React from 'react'

function ImagePreviewer({ openModal, toggle, image }) {

    return (
        <Modal size='4xl' show={openModal} onClose={toggle}>
            <Modal.Header>Preview</Modal.Header>
            <Modal.Body>
                <div>
                    <img src={image} className='w-full object-contain' alt="" />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='ms-auto' color="gray" onClick={toggle}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ImagePreviewer
