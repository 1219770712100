import React from 'react'
import line from '../../assetsRoot/heading/Line 415.png'
import travel from '../../assetsRoot/home/Rectangle 34625532.png'
import aboutimg from '../../assetsRoot/heading/ABOUT US.png'
import { FaArrowRight } from 'react-icons/fa6'

function AboutUs() {
    return (
        <>
            <div id="about" className='px-[5%] xl:px-[8%] py-12 bg-background1 '>
                <div className='flex items-center justify-between  lg:pb-5 gap-12 mb-8'>
                    <div className='xl:block lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                    <div className='flex justify-center items-end relative'>
                        <img className='relative' src={aboutimg} alt="our fleet" />
                        <h1 className='absolute -bottom-4 text-3xl font-bold text-primary uppercase '>about us</h1>
                    </div>
                    <div className='xl:block lg:block md:block hidden'>
                        <img src={line} alt="" />
                    </div>
                </div>
                <div className='flex justify-center text-center'>
                    <p className='pb-8 text-headingpara text-para text-sm xl:text-base w-full lg:w-[70%] '>Committed to seamless travel experiences, we provide reliable and professional transportation services tailored to exceed your expectations.</p>
                </div>
                <div className='grid grid-cols-12 lg:gap-20'>
                    <div className='col-span-12 lg:col-span-5 xl:col-span-5'>
                        <img src={travel} alt="Traveling" className='h-full object-cover border-[1px] border-primary rounded-lg' />
                    </div>
                    <div className='col-span-12 lg:col-span-7 xl:col-span-7 '>
                        <h2 className='text-xl lg:text-3xl xl:text-4xl font-semibold pt-5  lg:pt-0 xl:pt-0 pb-0 lg:pb-5  lg:w-[70%] xl:w-[90%] ' style={{ lineHeight: '3rem' }}>
                            Our Journey Begins Here:
                            <span className='text-secondary'>  Exceptional  </span>
                            Travel Services
                        </h2>
                        <p className='pb-2 lg:pb-5 xl:pb-7 text-sm xl:text-base text-para'>Welcome to ANP Travels India Pvt Ltd,
                            where experience meets excellence in company transport management. With extensive expertise and a deep
                            understanding of transport systems, our team excels in catering to the needs of multinational organizations,
                            effortlessly managing up to 8000+ employees and over 1500+ vehicles.</p>
                        <p className='pb-2 lg:pb-5 xl:pb-7 text-sm xl:text-base text-para'>At ANP Travels, we pride ourselves on our flexibility, agility in responding to change, and meticulous
                            organization skills. We specialize in planning and executing seamless transport schedules, ensuring
                            that every journey is smooth and efficient. Our dedicated team is committed to delivering exceptional
                            customer experiences, prioritizing your satisfaction at every step.</p>
                        <p className='pb-2 lg:pb-5 xl:pb-7 text-sm xl:text-base text-para'>Whether you're looking for reliable daily transport solutions or planning a complex logistical operation,
                            ANP Travels is your trusted partner. Let us take care of your transport needs while you focus on what matters
                            most – your business.</p>
                        <div className='mt-3 lg:mt-0'>
                            <button className='bg-gradient-to-r from-green to-primary flex py-2 px-3 border-none text-white rounded-[30px]'>
                                Know More
                                <span className='ms-2 pt-1'><FaArrowRight /></span>
                            </button>
                        </div>
                    </div>
                </div >

            </div >
        </>
    )
}

export default AboutUs
