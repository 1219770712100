import React from 'react'
import App from '../App'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes
